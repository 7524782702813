import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import { useTranslation, Link, FormatDate } from "../i18n"

import GridLayout from "../Layout/GridLayout"
import { Image, Icon, ImageData, Button } from "../Layout/Components"

import { Seo } from "../seo/"

type ArticleData = {
  date: string
  title: string
  details: {
    featuredImage: ImageData
    url: string
    excerpt: string
  }
  newspapers: {
    nodes: [
      {
        name: string
        slug: string
      }
    ]
  }
  tags: {
    nodes: [
      {
        slug: string
        name: string
      }
    ]
  }
}

type Props = {
  data: {
    wordPress: {
      pressArticles: {
        nodes: ArticleData[]
      }
      newspapers: {
        nodes: [
          {
            name: string
            slug: string
            details: {
              logo: ImageData
            }
          }
        ]
      }
    }
  }
}

const RassegnaStampa: React.FC<Props> = ({
  data: {
    wordPress: {
      pressArticles: { nodes: articles },
      newspapers: { nodes: newspapers },
    },
  },
}) => {
  const { t } = useTranslation([])

  const Article: React.FC<{ data: ArticleData }> = ({ data: article }) => {
    // console.log("Newspaper: ", article)
    // console.log("Newspaper: ", article.newspapers.nodes[0]?.slug)
    return (
      <Link to={article.details.url} target="_blank" className="card__press_vert">
        <div className="card__press_vert-cover">
          <div className="card__press_vert-image-cover"></div>
          <Image file={article.details.featuredImage} type="cover" className="card__press_vert-image" />
          <Image
            file={newspapers.find((newspaper) => newspaper.slug == article.newspapers.nodes[0]?.slug)?.details.logo}
            type="contain"
            className="card__press_vert-logo"
          />
        </div>
        <div className="card__press_vert-content">
          <h3 className="card__press_vert-newspaper" dangerouslySetInnerHTML={{ __html: article.newspapers.nodes[0]?.name }}></h3>
          <h3 className="card__press_vert-title" dangerouslySetInnerHTML={{ __html: article.title }}></h3>
          <p className="card__press_vert-excerpt" dangerouslySetInnerHTML={{ __html: article.details.excerpt }}></p>
          <div className="card__press_vert-content-background"></div>
        </div>
        <div className="card__press_vert-date">
          <FormatDate date={article.date} />
        </div>
        <Button to={null} type="primary" external title={t("Visualizza")} className="card__press_vert-cta" />
      </Link>
    )
  }

  return (
    <GridLayout>
      <Seo title={t("Rassegna Stampa")} />
      <div className="row row--center">
        <div className="col--12">
          <h1>{t("La rassegna stampa")}</h1>
          <p className="mb-none">{t("Di seguito una sintesi di quello che giornali, radio e tv dicono di Ecoverso")}</p>
          <p>
            {t("Per informazioni scrivici a ")}
            <a href="mailto:press@ecoverso.org">press@ecoverso.org</a>
          </p>
        </div>
      </div>
      <div className="row row--center row--no-grid pt-none">
        <div className="press">
          {articles.map((article: ArticleData, i) => (
            <Article data={article} key={i} />
          ))}
        </div>
      </div>
    </GridLayout>
  )
}

export const query = graphql`
  query PressReleaseQuery {
    wordPress {
      pressArticles(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
        nodes {
          date
          title
          details {
            featuredImage {
              altText
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
            url
            excerpt
          }
          newspapers {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
        }
      }
      newspapers {
        nodes {
          name
          details {
            logo {
              altText
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1080) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
          slug
        }
      }
    }
  }
`

export default RassegnaStampa
